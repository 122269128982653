import React, { createContext, useContext, useState, useEffect } from "react";

import useOnboardingCommon from "./useOnboardingCommon";

const OnboardingLiveContext = createContext()

export const OnboardingLiveProvider = ({ onboardingId, children }) => {
    const {
        isLoading,
        style,
        settings,
    } = useOnboardingCommon({ onboardingId })

    const [userValues, setUserValues] = useState([])
    const [downloadURL, setDownloadURL] = useState(null);

    useEffect(() => {
        if (settings?.deeplink_scheme) {
            setDownloadURL(settings.deeplink_scheme);
        }
    }, [settings]);

    function handleUpdateProperty(property, value) {
        setUserValues(prevState => ({ ...prevState, [property]: value }))
    }

    return (
        <OnboardingLiveContext.Provider
            value={{
                isLoading,
                style,
                settings,
                userValues,
                handleUpdateProperty,
                downloadURL,
                setDownloadURL
            }}
        >
            {children}
        </OnboardingLiveContext.Provider>
    );
};

export const useOnboardingLiveContext = () => {
    return useContext(OnboardingLiveContext)
};