import {
  FaAppStoreIos,
  FaBell,
  FaCheck,
  FaDatabase,
  FaDollarSign,
  FaEllipsisH,
  FaExpand,
  FaHandPeace,
  FaICursor,
  FaImage,
  FaMagic,
  FaMinus,
  FaPaintRoller,
  FaPen,
  FaRandom,
  FaSlidersH,
  FaSpinner,
  FaTasks,
  FaTextHeight,
  FaUser,
  FaVideo,
} from "react-icons/fa";

import ErrorStatuts from "../../common/ErrorStatus";
import OptionsSettings from "../../settings/OptionsSettings";
import React from "react";
import Settings from "../../settings/Settings";
import { useOnboardingConfigContext } from "../../../shared/hooks/useOnboardingConfig";

const type_structure = {
  property: "string",
};

const common_structure = {
  title: "string",
  description: "long_string",
  continue_button_title: "string",
};

const top_structure = {
  progress_bar_enabled: "boolean",
  progress_bar_title: "string",
  back_enabled: "boolean",
  skip_enabled: "boolean",
  skip_button_title: "string",
};

const settings_structure = {
  welcome: {
    title: "Welcome",
    icon: <FaHandPeace />,
    settings: {
      continue_button_title: "string",
      continue_mode: ["next_step", "next_carrousel_page"],
    },
  },
  intermediate: {
    title: "Image",
    icon: <FaImage />,
    settings: {
      image: "image",
      image_position: ["above_title", "below_title", "below_description"],
      image_size: ["large", "medium", "small"],
    },
  },
  video: {
    title: "Video",
    icon: <FaVideo />,
    settings: {
      video: "video",
      background_color: "color",
      badge_text: "string",
      badge_color: "color",
    },
  },
  select: {
    title: "Settings",
    icon: <FaSlidersH />,
    settings: {
      select_options: "options",
      columns: "number",
      multi_select: "boolean",
      direct_continue: "boolean",
      display_description_on_select: "boolean",
      icons_position: ["left", "right"],
      icons_size: "number",
      vertical_padding: "number",
    },
  },
  text_input: {
    title: "Text input",
    icon: <FaICursor />,
    settings: {
      placeholder: "string",
    },
  },
  picker: {
    title: "Picker",
    icon: "",
    settings: {
      picker_mode: ["hour", "date", "year", "custom"],
      // picker_options: 'string'
    },
  },
  notification: {
    title: "Notification",
    icon: <FaBell />,
    settings: {
      image: "image",
      image_position: ["above_title", "below_title", "below_description"],
      image_size: ["large", "medium", "small"],
    },
  },
  loading: {
    title: "Loading",
    icon: <FaSpinner />,
    settings: {
      loading_title: "string",
      completed_title: "string",
      completed_description: "long_string",
      loading_steps: "array",
    },
  },
  custom: {
    title: "Custom",
    icon: <FaExpand />,
    settings: {
      custom_name: "string",
      custom_step_type: ["contentOnly", "fullScreen"],
    },
  },
  download: {
    title: "Download",
    icon: <FaAppStoreIos />,
    settings: {
      appstore_link: "string",
      playstore_link: "string",
      image: "image",
      image_position: ["above_title", "below_title", "below_description"],
      image_size: ["large", "medium", "small"],
    },
  },
  signup: {
    title: "Signup",
    icon: <FaUser />,
    settings: {
      mode: ["emailOnly"],
    },
  },
};

function RightPanel({ editorMode }) {
  const {
    style,
    setStyle,
    settings,
    setSettings,
    selectedStep,
    handleUpdateStep,
    errors,
  } = useOnboardingConfigContext();
  const step = selectedStep;

  if (!step) return null;

  const handleOptionUpdate = (path, value, index) => {
    console.log(path, value, index);
    const newOptions = [...step[path]];
    newOptions[index] = value;
    handleUpdateStep({ ...step, [path]: newOptions });
  };

  const addOption = (path, emptyValue) => {
    handleUpdateStep({ ...step, [path]: [...step[path], emptyValue] });
  };

  const removeOption = (path, index) => {
    const newOptions = [...step[path]];
    newOptions.splice(index, 1);
    handleUpdateStep({ ...step, [path]: newOptions });
  };

  return (
    <div className="w-80 py-2 pr-2 bg-gray-100 overflow-y-auto">
      {editorMode === "content" ? (
        <div className="space-y-1">
          {/* TYPE */}
          <Settings
            settings={type_structure}
            step={step}
            handleUpdate={handleUpdateStep}
          />
          <Settings
            title="Conditions"
            icon={<FaRandom />}
            settings={{ conditions: "conditions" }}
            step={step}
            handleUpdate={handleUpdateStep}
          />
          {/* TOP BAR */}
          {step.type === "welcome" ||
          step.type === "loading" ||
          (step.type === "custom" &&
            step.custom_step_type === "fullScreen") ? null : (
            <Settings
              title="Top Bar"
              icon={<FaMinus />}
              settings={top_structure}
              step={step}
              handleUpdate={handleUpdateStep}
            />
          )}

          {/* CONTENT */}
          {step.type === "welcome" ? (
            <OptionsSettings
              title="Screens"
              icon={<FaEllipsisH />}
              settings={{
                title: "string",
                description: "long_string",
                image: "image",
                media_position: [
                  "above_title",
                  "below_title",
                  "below_description",
                ],
                image_size: ["large", "medium", "small"],
              }}
              options={step.content}
              path={"content"}
              handleOptionChange={handleOptionUpdate}
              addOption={addOption}
              removeOption={removeOption}
            />
          ) : step.type === "loading" || step.type === "custom" ? null : (
            <Settings
              title="Content"
              icon={<FaPen />}
              settings={common_structure}
              step={step}
              handleUpdate={handleUpdateStep}
            />
          )}

          {step.type === "select" && (
            <OptionsSettings
              title="Choices"
              icon={<FaCheck />}
              settings={{
                title: "string",
                description: "long_string",
                icon: "image",
                reset: "boolean",
              }}
              options={step.select_options}
              path={"select_options"}
              handleOptionChange={handleOptionUpdate}
              addOption={addOption}
              removeOption={removeOption}
            />
          )}

          {/* SETTING */}
          {settings_structure[step.type] && (
            <Settings
              title={settings_structure[step.type]?.title}
              icon={settings_structure[step.type]?.icon}
              settings={settings_structure[step.type]?.settings}
              step={step}
              handleUpdate={handleUpdateStep}
            />
          )}

          {step.type === "loading" && (
            <OptionsSettings
              title="Loading steps"
              icon={<FaTasks />}
              settings={{ title: "string" }}
              options={step.loading_steps}
              path={"loading_steps"}
              handleOptionChange={handleOptionUpdate}
              addOption={addOption}
              removeOption={removeOption}
            />
          )}

          {step.type === "picker" && step.picker_mode === "custom" && (
            <OptionsSettings
              title="Custom values"
              settings={{ property: "string", values: "array_string" }}
              options={step.picker_options}
              path={"picker_options"}
              handleOptionChange={handleOptionUpdate}
              addOption={addOption}
              removeOption={removeOption}
            />
          )}

          {step.type === "picker" && step.picker_mode === "year" && (
            <Settings
              settings={{ min: "number", max: "number" }}
              step={step}
              handleUpdate={handleUpdateStep}
            />
          )}
        </div>
      ) : editorMode === "design" ? (
        <div className="space-y-1">
          <Settings
            title="General"
            icon={<FaPaintRoller />}
            settings={{
              background_color: "color",
              main_color: "color",
              secondary_color: "color",
              text_color: "color",
              progress_bar_separated_steps: "boolean",
              button_corner_radius: "number",
              logo: "image",
            }}
            step={style}
            handleUpdate={setStyle}
          />
          <Settings
            title="Text"
            icon={<FaTextHeight />}
            settings={{
              title_font: "string",
              body_text_font: "string",
              title_font_size: "number",
              body_font_size: "number",
              content_left_aligned: "align",
            }}
            step={style}
            handleUpdate={setStyle}
          />
          <Settings
            title="Multiple choice"
            prefix="multiple_choice_"
            icon={<FaCheck />}
            settings={{
              multiple_choice_background_color: "color",
              multiple_choice_border_color: "color",
              multiple_choice_border_width: "number",
              multiple_choice_left_aligned: "align",
              multiple_choice_corner_radius: "number",
            }}
            step={style}
            handleUpdate={setStyle}
          />
          <Settings
            title="Effects"
            icon={<FaMagic />}
            settings={{
              fade_transition: "boolean",
              transition_duration: "number",
              haptic_enabled: "boolean",
            }}
            step={style}
            handleUpdate={setStyle}
          />
        </div>
      ) : editorMode === "settings" ? (
        <div className="space-y-1">
          <Settings
            title="App"
            icon={<FaAppStoreIos />}
            settings={{
              app_name: "string",
              app_code: "string",
              slug: "string",
              ios_download_link: "string",
              android_download_link: "string",
              app_logo: "image",
              deeplink_scheme: "string",
              email_template_id: "string",
            }}
            step={settings}
            handleUpdate={setSettings}
          />
          <Settings
            title="Tracking"
            icon={<FaDatabase />}
            settings={{
              amplitude_api_key: "string",
              adjust_app_token: "string",
              meta_pixel_id: "string",
              adjust_purchase_event_token: "string",
              google_tag_id: "string",
            }}
            step={settings}
            handleUpdate={setSettings}
          />
          <Settings
            title="Payment"
            icon={<FaDollarSign />}
            settings={{
              revenuecat_api_key: "string",
            }}
            step={settings}
            handleUpdate={setSettings}
          />
          <ErrorStatuts
            title="Missing settings"
            errors={
              errors && errors.filter((obj) => obj.type === "missing_setting")
            }
          />
        </div>
      ) : null}
    </div>
  );
}

export default RightPanel;
