import React, { createContext, useContext, useState } from "react";

const UserDetailsContext = createContext();

export const UserDetailsProvider = ({ children }) => {
  const [userId, setUserId] = useState("");

  return (
    <UserDetailsContext.Provider
      value={{
        userId,
        setUserId,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export const useUserDetailsContext = () => {
  return useContext(UserDetailsContext);
};
