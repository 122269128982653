import React, { useEffect } from "react";
import {
  TrackableEvents,
  sendEvent,
} from "../../../../shared/helpers/TrackingManager";

import Logo from "../../../common/Logo";
import { useOnboardingContext } from "../../../../shared/hooks/useOnboarding";
import { useOnboardingLiveContext } from "../../../../shared/hooks/useOnboardingLive";
import { useUserDetailsContext } from "../../../../shared/hooks/useUserDetails";

export default function PaymentSuccessfulWrapper({ userValues }) {
  const { onboardingId, settings } = useOnboardingContext();
  const { userId } = useUserDetailsContext();
  const { downloadURL } = useOnboardingLiveContext();

  const downloadURLWithUserId = generateDownloadURLWithUserId();

  useEffect(() => {
    const onPageLoad = () => {
      sendEvent(TrackableEvents.ONB_FINISHED);
    };

    async function sendMail() {
      await fetch(`${process.env.REACT_APP_SERVER_URL}/v1/send_welcome_email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          app: `${settings.app_code}-ios`.toUpperCase(),
          app_name: settings.app_name,
          deeplink_url: downloadURLWithUserId,
          email_template_id: settings.email_template_id,
        }),
      });
    }

    sendMail();

    onPageLoad();
  }, []);

  const handleDownload = (event) => {
    // Copy userId to clipboard
    if (userId) {
        navigator.clipboard.writeText(userId)
    }
    
    sendEvent(TrackableEvents.DOWNLOAD_APP_BUTTON_CLICK);
  };

  function generateDownloadURLWithUserId() {
    const url = `${downloadURL}&user_id=${userId}`;

    return url;
  }

  return (
    <div className="space-y-2 text-center">
      <div className="bg-white border rounded-lg p-4 space-y-4">
        <div className="bg-green-100 p-2 rounded-lg font-medium">
          ✅ Payment successful
        </div>
        <div className="font-semibold text-2xl">
          Welcome{settings?.app_name && " to " + settings.app_name}!
        </div>
        <Logo onboardingId={onboardingId} />
        <div>
          We are so happy to have you! Now you have full access to our amazing
          content.
        </div>
      </div>
      <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 space-y-4">
        <div className="font-semibold text-lg">To activate your account:</div>
        <div className="space-y-2">
          <div className="font-semibold">
            <span className="bg-slate-50 text-sm px-1.5 py-0.5 mr-2 rounded border">
              1
            </span>
            Download the app
          </div>
          <div>Tap the button below to dowload our app:</div>
          <div className="mt-2">
            {downloadURL && (
              <a href={downloadURLWithUserId} onClick={handleDownload}>
                <div className="p-3 bg-blue-600 rounded-lg text-white font-medium cursor-pointer hover:bg-blue-500">
                  Download for free
                </div>
              </a>
            )}
            {!downloadURL && (
              <div className="p-4 mt-2 text-red-700 border border-red-200 rounded-lg bg-red-50 space-y-2">
                <span className="text-sm">Error with the download URL</span>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-2">
          <div className="font-semibold">
            <span className="bg-slate-50 text-sm px-1.5 py-0.5 mr-2 rounded border">
              2
            </span>
            Sign up
          </div>
        </div>
        <div className="space-y-2">
          <div className="font-semibold">
            <span className="bg-slate-50 text-sm px-1.5 py-0.5 mr-2 rounded border">
              3
            </span>
            Enjoy! 🎉
          </div>
        </div>
      </div>
    </div>
  );
}
