import React, { createContext, useContext, useEffect, useState } from "react";
import { Timestamp, doc, updateDoc } from "firebase/firestore";
import { db, isSlugAvailable, storage } from "../helpers/Firebase";

import { downloadConfigFolder } from "../helpers/DownloadManager";
import { initialStyleData } from "../../data/InitialStyleData";
import isEqual from "lodash.isequal";
import toast from "react-hot-toast";
import useOnboardingCommon from "./useOnboardingCommon";

const initialStepData = {
  welcome: {
    type: "welcome",
    property: "welcome",
    content: [
      { title: "", description: "", image: "", media_position: "above_title" },
    ],
    continue_button_title: "Get started",
    image: "",
    image_position: "above_title",
    image_size: "large",
    continue_mode: "next_step",
  },
  intermediate: {
    type: "intermediate",
    property: "",
    title: "",
    description: "",
    progress_bar_enabled: true,
    progress_bar_title: "",
    skip_enabled: false,
    skip_button_title: "Skip",
    back_enabled: false,
    image: "",
    image_position: "above_title",
    image_size: "large",
    continue_button_title: "Continue",
  },
  video: {
    type: "video",
    property: "",
    title: "",
    description: "",
    badge_text: "",
    badge_color: "",
    progress_bar_enabled: false,
    progress_bar_title: "",
    skip_enabled: false,
    skip_button_title: "Skip",
    back_enabled: false,
    video: "",
    background_color: "",
    continue_button_title: "Continue",
  },
  select: {
    type: "select",
    property: "",
    title: "",
    description: "",
    progress_bar_enabled: true,
    progress_bar_title: "",
    skip_enabled: false,
    skip_button_title: "Skip",
    back_enabled: false,
    select_options: [{ title: "", description: "" }],
    columns: 1,
    multi_select: false,
    direct_continue: true,
    display_description_on_select: false,
    continue_button_title: "Continue",
    icons_position: "left",
    icons_size: 30,
  },
  text_input: {
    type: "text_input",
    property: "",
    title: "",
    description: "",
    progress_bar_enabled: true,
    progress_bar_title: "",
    skip_enabled: false,
    skip_button_title: "Skip",
    back_enabled: false,
    placeholder: "",
    input_mode: "text",
    continue_button_title: "Continue",
  },
  picker: {
    type: "picker",
    property: "",
    title: "",
    description: "",
    progress_bar_enabled: true,
    progress_bar_title: "",
    skip_enabled: false,
    skip_button_title: "Skip",
    back_enabled: false,
    picker_mode: "hour",
    picker_options: [],
    continue_button_title: "Continue",
  },
  notification: {
    type: "notification",
    property: "notification",
    title: "",
    description: "",
    progress_bar_enabled: false,
    progress_bar_title: "",
    skip_enabled: true,
    skip_button_title: "Later",
    back_enabled: false,
    continue_button_title: "Allow",
    image: "",
    image_position: "above_title",
    image_size: "large",
  },
  loading: {
    type: "loading",
    property: "loading",
    loading_title: "",
    completed_title: "",
    completed_description: "",
    loading_steps: [""],
  },
  custom: {
    type: "custom",
    property: "",
    custom_name: "",
    custom_step_type: "contentOnly",
    continue_button_title: "Continue",
    progress_bar_enabled: false,
    back_enabled: false,
    skip_enabled: false,
    progress_bar_title: "",
    skip_button_title: "Skip",
  },
  download: {
    type: "download",
    property: "download",
    title: "",
    description: "",
    progress_bar_enabled: false,
    skip_enabled: false,
    back_enabled: false,
    image: "",
    image_position: "above_title",
    image_size: "large",
  },
  signup: {
    type: "signup",
    property: "signup",
    continue_button_title: "Continue",
    mode: "emailOnly",
  },
  paywall: {
    type: "paywall",
    property: "paywall",
  },
};

const defaultContext = {
  style: initialStyleData,
};

const OnboardingConfigContext = createContext(defaultContext);

export const OnboardingConfigProvider = ({ onboardingId, children }) => {
  const {
    isLoading,
    name,
    setName,
    email,
    setEmail,
    steps,
    setSteps,
    style,
    setStyle,
    settings,
    setSettings,
    selectedStep,
    setSelectedStep,
    selectedStepIndex,
    stepsCount,
  } = useOnboardingCommon({ onboardingId });

  const docRef = doc(db, "onboardings", onboardingId);
  const [savedConfig, setSavedConfig] = useState(null);
  const isSaved = isEqual(savedConfig, { name, steps, style, settings });
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (onboardingId && !isLoading)
      setSavedConfig({ name, steps, style, settings });
  }, [onboardingId, isLoading]);

  useEffect(() => {
    validateConfig();
  }, [steps, settings]);

  function validateConfig() {
    // pk_test_51OXOZEGz1bZiAzq14DDaoLV9oQZXMcfKtrPne2zSty8LPb4wx7Ngqc929SscCwhgfpTOPpi5Xy56d0ZD7zRhl1e300iU6Bizg7
    if (!steps || !settings) return;

    let arr = [];
    if (
      steps.some((obj) => obj.type === "paywall") &&
      (!settings.ios_download_link || settings.ios_download_link === "")
    ) {
      arr = [
        ...arr,
        { type: "missing_setting", property: "ios_download_link" },
      ];
    }
    if (
      steps.some((obj) => obj.type === "paywall") &&
      (!settings.revenuecat_api_key || settings.revenuecat_api_key === "")
    ) {
      arr = [
        ...arr,
        { type: "missing_setting", property: "revenuecat_api_key" },
      ];
    }
    if (
      steps.some((obj) => obj.type === "paywall") &&
      (!settings.meta_pixel_id || settings.meta_pixel_id === "")
    ) {
      arr = [
        ...arr,
        { type: "missing_setting", property: "meta_pixel_id" },
      ];
    }
    if (
      steps.some((obj) => obj.type === "paywall") &&
      (!settings.slug || settings.slug === "")
    ) {
      arr = [
        ...arr,
        { type: "missing_setting", property: "slug" },
      ];
    }
    if (
      !settings.deeplink_scheme || settings.deeplink_scheme === ""
    ) {
      arr = [
        ...arr,
        { type: "missing_setting", property: "deeplink_scheme" },
      ];
    }
    if (
      !settings.email_template_id || settings.email_template_id === ""
    ) {
      arr = [
        ...arr,
        { type: "missing_setting", property: "email_template_id" },
      ];
    }
    setErrors(arr);
  }

  const handleAddStep = (type) => {
    const newStep = { ...initialStepData[type] };
    setSteps([...steps, newStep]);
    setSelectedStep(newStep);
  };

  const handleUpdateStep = (updatedStep) => {
    const newSteps = steps.map((step) =>
      step === selectedStep ? updatedStep : step
    );
    setSteps(newSteps);
    setSelectedStep(updatedStep);
  };

  function handleDuplicateStep(step) {
    const newStep = { ...step, property: step.property + " (copy)" };
    setSteps([...steps, newStep]);
    setSelectedStep(newStep);
  }

  const handleDeleteStep = (index) => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setSteps(newSteps);
  };

  async function handleSave() {
    const { slug } = settings;

    if (slug) {
      const slugAvailable = await isSlugAvailable(onboardingId, slug);
      if (!slugAvailable) {
        toast.error("Slug is already taken");
        return;
      }
    }

    const updatedConfig = { name, style, steps, settings };
    toast.promise(
      updateDoc(docRef, {
        ...updatedConfig,
        updated: Timestamp.now(),
      }).then(() => setSavedConfig(updatedConfig)),
      {
        loading: "Saving...",
        success: <b>Onboarding saved!</b>,
        error: <b>Could not save</b>,
      }
    );
  }

  const handleDownload = async () => {
    downloadConfigFolder(storage, onboardingId, style, steps, name);
  };

  return (
    <OnboardingConfigContext.Provider
      value={{
        isLoading,
        name,
        setName,
        email,
        setEmail,
        style,
        setStyle,
        steps,
        setSteps,
        settings,
        setSettings,
        selectedStep,
        setSelectedStep,
        selectedStepIndex,
        stepsCount,
        isSaved,
        handleAddStep,
        handleUpdateStep,
        handleDuplicateStep,
        handleDeleteStep,
        handleSave,
        handleDownload,
        errors,
      }}
    >
      {children}
    </OnboardingConfigContext.Provider>
  );
};

export const useOnboardingConfigContext = () => {
  return useContext(OnboardingConfigContext);
};
