import React, { useState } from "react";

import ContentWrapper from "./ContentWrapper";
import ContinueButton from "../../content/ContinueButton";
import { FaExclamationTriangle } from "react-icons/fa";
import Logo from "../../../../components/common/Logo";
import TextInput from "../../content/TextInput";
import { useOnboardingContext } from "../../../../shared/hooks/useOnboarding";

export default function SignupWrapper({
  isEditMode,
  step,
  style,
  userValues,
  handleUpdateText = () => {},
  handleNextStep = () => {},
}) {
  const [email, setEmail] = useState("");
  const { onboardingId } = useOnboardingContext();
  const [hasError, setHasError] = useState(false);
  const { settings } = useOnboardingContext({ onboardingId });
  const mode = step?.mode;

  function isEmailValid(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(email);

    return isValid;
  }

  async function handleContinue() {
    setHasError(false);

    if (!isEmailValid(email)) {
      setHasError(true);
      return;
    }

    handleNextStep();
  }

  return (
    <>
      <div className="my-4">
        <Logo onboardingId={onboardingId} />
      </div>
      {hasError && (
        <div className="p-4 mb-4 text-red-700 border border-red-200 rounded-lg bg-red-50 space-y-2">
          <div className="flex items-center space-x-1 text-sm">
            <FaExclamationTriangle />
            <h3 className="font-medium">Error</h3>
          </div>
          <div className="text-xs">
            The email you entered is not valid. Please enter a valid email
            address.
          </div>
        </div>
      )}
      <ContentWrapper
        isEditMode={isEditMode}
        step={step}
        style={style}
        image={settings.app_logo}
        handleUpdateText={(property, value) =>
          handleUpdateText(property, value)
        }
      />
      <div className="my-4">
        {mode === "emailOnly" && (
          <TextInput
            isEditable={false}
            placeholder={"Email"}
            mainColor={style.main_color}
            onChange={(value) => (isEditMode ? null : setEmail(value))}
            value={email}
          />
        )}
      </div>
      <ContinueButton
        isEditable={isEditMode}
        isEnabled={
          step.type !== "select" || userValues[step.property]?.length > 0
        }
        title={step.continue_button_title}
        backgroundColor={style.main_color}
        borderRadius={parseInt(style.button_corner_radius)}
        fontSize={parseInt(style.body_font_size)}
        setTitle={(value) => handleUpdateText("continue_button_title", value)}
        onClick={handleContinue}
      />
    </>
  );
}
