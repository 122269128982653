import * as amplitude from "@amplitude/analytics-browser";

import { LogLevel } from "@amplitude/analytics-types";
import { isDevMode } from "./Dev";

export function initializeAmplitude(apiKey, userId) {
  const isDev = isDevMode();

  amplitude.init(apiKey, userId, {
    logLevel: isDev ? LogLevel.Verbose : LogLevel.None,
    defaultTracking: { pageViews: false, sessions: true },
    attribution: { disabled: false },
  });
}

export function trackEvent(eventName, eventProperties) {
  amplitude.track(eventName, eventProperties);
}

export function setUserProperty(property, value) {
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set(property, value);
  amplitude.identify(identifyEvent);
}