import * as Adjust from "./Adjust";
import * as Amplitude from "./Amplitude";
import * as Facebook from "./Facebook";
import * as Google from "./Google";

export function initTrackers(settings, userId) {
  const { adjust_app_token, amplitude_api_key, meta_pixel_id, google_tag_id } =
    settings;

  Google.initializeGoogleTag(google_tag_id);
  Facebook.initializeFacebookPixel(meta_pixel_id);
  Amplitude.initializeAmplitude(amplitude_api_key, userId);
  Adjust.initializeAdjust(adjust_app_token, (e, attribution) => {
    setUserProperty("web_adid", attribution.adid);
    setUserProperty("tracker_token", attribution.tracker_token);
    setUserProperty("tracker_name", attribution.tracker_name);
    setUserProperty("network", attribution.network);
    setUserProperty("campaign", attribution.campaign);
    setUserProperty("adgroup", attribution.adgroup);
    setUserProperty("creative", attribution.creative);
  });

  Adjust.setAdjustExternalDeviceId(userId)
}

export function sendEvent(event, eventProperties) {
  Amplitude.trackEvent(event, eventProperties);
  Facebook.sendFBEvent(event, eventProperties);
  Google.trackPageView(event);
}

export function setUserProperty(property, value) {
  Amplitude.setUserProperty(property, value);
}

export function trackPurchase(adjustEventToken, revenue, currency, productId) {
  Adjust.trackPurchase(adjustEventToken, revenue, currency);
  Amplitude.trackEvent(TrackableEvents.PURCHASE_SUCCESSFUL, {
    revenue,
    currency,
    productId,
  });
  Facebook.trackPurchase(revenue, currency, productId);
  Google.trackPurchase(revenue, currency, productId);
}

export const TrackableEvents = {
  ONB_STARTED: "onboarding_started",
  ONB_FINISHED: "onboarding_finished",
  PURCHASE_STARTED: "purchase_started",
  PURCHASE_CANCELLED: "purchase_cancelled",
  PURCHASE_SUCCESSFUL: "purchase_successful",
  PURCHASE_ERROR: "purchase_error",
  PAGE_VIEW: "page_view",
  DOWNLOAD_APP_BUTTON_CLICK: "download_app_button_click",
};
