import {
  OnboardingLiveProvider,
  useOnboardingLiveContext,
} from "../shared/hooks/useOnboardingLive";
import {
  OnboardingProvider,
  useOnboardingContext,
} from "../shared/hooks/useOnboarding";
import React, { useEffect, useState } from "react";
import {
  TrackableEvents,
  initTrackers,
  sendEvent,
  setUserProperty,
} from "../shared/helpers/TrackingManager";
import { UserDetailsProvider, useUserDetailsContext } from "../shared/hooks/useUserDetails";

import ContentGenerator from "../components/preview/layout/ContentGenerator";
import Spinner from "../components/common/Spinner";
import TopBar from "../components/preview/content/TopBar";
import { getOnboardingId } from "../shared/helpers/Firebase";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

export default function FormPage() {
  const params = useParams();
  const slug = params?.slug;
  const [onboardingId, setOnboardingId] = useState(null);
  const [status, setStatus] = useState("loading");

  async function handleOnboardingId(slug) {
    const id = await getOnboardingId(slug);

    if (id) {
      setOnboardingId(id);
      setStatus("idle");
    } else {
      setStatus("error");
    }
  }

  useEffect(() => {
    handleOnboardingId(slug);
  }, [slug]);

  if (status === "loading") {
    return (
      <div className="flex h-[100dvh] justify-center items-center text-4xl">
        <Spinner />
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="flex h-[100dvh] justify-center items-center text-4xl">
        404 - Page Not Found
      </div>
    );
  }

  return (
    <OnboardingProvider onboardingId={onboardingId}>
      <OnboardingLiveProvider onboardingId={onboardingId}>
        <UserDetailsProvider>
            <div className="flex h-[100dvh]">
              <CenterPanel />
            </div>
        </UserDetailsProvider>
      </OnboardingLiveProvider>
    </OnboardingProvider>
  );
}

function CenterPanel() {
  const { style, settings, userValues, handleUpdateProperty, setDownloadURL } =
    useOnboardingLiveContext();
  const {
    step,
    selectedStep,
    stepsCount,
    selectedStepIndex,
    handleNextStep,
    handlePreviousStep,
  } = useOnboardingContext();
  const { setUserId } = useUserDetailsContext();
  const [fade, setFade] = useState("fadeIn");
  const FADE_DURATION = 400;

  useEffect(() => {
    if (fade === "fadeOut") {
      const timer = setTimeout(() => {
        setFade("fadeIn");
      }, FADE_DURATION);

      return () => clearTimeout(timer);
    }
  }, [fade]);

  const handleChangeStep = (increment = true) => {
    setFade("fadeOut");
    const timer = setTimeout(() => {
      increment ? handleNextStep() : handlePreviousStep();
    }, FADE_DURATION);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (selectedStep) {
      sendEvent(TrackableEvents.PAGE_VIEW, {
        page: selectedStep.content
          ? selectedStep.content[0].title
          : selectedStep.title,
        type: selectedStep.type,
        step: selectedStepIndex + 1,
      });
    }
  }, [selectedStep]);

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      const uuid = uuidv4();
      
      initTrackers(settings, uuid);
      setUserId(uuid);

      const urlRawParams = decodeURIComponent(window.location.search);
      // For debugging purposes in production
      setUserProperty("web_onboarding_url", urlRawParams);

      const urlParams = new URLSearchParams(urlRawParams);
      const downloadURL = urlParams.get("download_url");
      setDownloadURL(downloadURL);

      sendEvent(TrackableEvents.ONB_STARTED);
    }
  }, [
    setDownloadURL,
    settings,
    setUserId,
  ]);

  return (
    <div
      className="h-[100dvh] overflow-y-auto flex flex-1 relative"
      style={{ backgroundColor: style.background_color }}
    >
      {selectedStep && (
        <div className="flex flex-1 flex-col items-center">
          <div className="w-full max-w-[1200px]">
            {step?.type === "custom" &&
            step?.custom_step_type === "fullScreen" &&
            step.type !== "video" ? null : (
              <TopBar
                layout="one_line"
                progressBarEnabled={selectedStep?.progress_bar_enabled}
                backEnabled={selectedStep?.back_enabled}
                backTitle="←"
                skipEnabled={selectedStep?.skip_enabled}
                skipTitle={selectedStep?.skip_button_title}
                stepsCount={stepsCount}
                currentStep={selectedStepIndex}
                separatedSteps={style?.progress_bar_separated_steps}
                backgroundColor={style?.background_color}
                mainColor={style?.main_color}
                secondaryColor={style?.secondary_color}
                handleBack={handlePreviousStep}
                handleSkip={handleNextStep}
                logo={style?.logo}
              />
            )}
          </div>
          <div
            className={`w-full h-full max-w-[420px] mt-10 transition-opacity duration-500 ${
              fade === "fadeIn" ? "opacity-100" : "opacity-0"
            }`}
          >
            <ContentGenerator
              isEditMode={false}
              step={selectedStep}
              style={style}
              settings={settings}
              stepsCount={stepsCount}
              userValues={userValues}
              currentStepIndex={selectedStepIndex}
              handleUpdateProperty={handleUpdateProperty}
              handleNextStep={() => handleChangeStep(true)}
              handlePreviousStep={() => handleChangeStep(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
